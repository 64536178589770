import React from 'react'
import Layout from '../components/Layout'
import { MdMusicNote } from "react-icons/md"
import Banner from '../components/Banner'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SeoHelmet from '../components/SeoHelmet'

const PostTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: { title, category, date, musicLink, listening, description },
      body,
    },
  } = data

  return (
    <Layout>
      <SeoHelmet title={title} description={description} />
        <div className="columns">
          <article className="column is-three-quarters">
            <div className="article underline">
              <span className="tag article__tag" id={category}>{category}</span>
              <h1 className="title article__title space is-4">{title}</h1>
                <p className="article__music">
                  <MdMusicNote></MdMusicNote>
                  listening to <a href={musicLink} rel="noopener noreferrer" target="_blank" className="article__band"> {listening} </a> on {date}
                </p>
            </div>
            <div className="content">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </article>
          <Banner />
        </div>
    </Layout>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        listening
        musicLink
        description
        date(formatString: "MMMM Do, YYYY")
        readTime
        slug
      }
      body
    }
  }
`

export default PostTemplate
